<template>
  <div>
    <table class="print-insalubrity table-bordered">
      <tr>
        <th colspan="1">
          <img src="../assets/images/logo-nova.png" alt="HOFácil" />
        </th>
        <th colspan="5" class="emph">
          Relatório de impressão gerado do software de Higiene Ocupacional para
          agentes químicos HO Fácil Web.
          <br />
          As informações aqui mostradas são relativas às pesquisas para
          verificação legal conforme a aba LTCAT.
        </th>
      </tr>
      <template v-for="item in summary">
        <template v-for="field in model.tableFieldsPrint">
          <tr
            :key="`key_${item.legalSearchId}_${field.key}`"
            v-if="model.getActivity(item)"
          >
            <template v-if="field.key === 'decret3048 - title'">
              <td rowspan="2" class="emph col-first">
                {{ field.value }}
              </td>
              <td
                class="emph"
                v-for="attribute in field.attributes"
                :key="`${attribute.key}_title`"
              >
                {{ attribute.value }}
              </td>
            </template>
            <template v-else-if="field.key === 'decret3048 - value'">
              <td
                class="data-center"
                colspan="1"
                v-for="attribute in field.attributes"
                :key="`${attribute.key}_value`"
                v-html="model.getValue(attribute.key, item)"
              ></td>
            </template>
            <template v-else-if="field.key === 'evaluation'">
              <td class="emph">{{ field.value }}</td>
              <td
                clas="data-center"
                colspan="1"
                v-html="model.getValue(field.key, item)"
              ></td>

              <td colspan="1" class="emph">{{ field.linach.value }}</td>
              <td
                class="data-center"
                colspan="3"
                v-html="model.getValue(field.linach.key, item)"
              ></td>
            </template>
            <template v-else>
              <td class="emph">{{ field.value }}</td>
              <td colspan="5" v-html="model.getValue(field.key, item)"></td>
            </template>
          </tr>

          <tr :key="`key_${item}_${field.key}`" v-else>
            <template v-if="field.key === 'toleranceList'">
              <td class="emph">{{ field.value }}</td>
              <td colspan="5">Agente necessita de determinação de atividade</td>
            </template>
            <template v-else>
              <td class="emph">{{ field.value }}</td>
              <td colspan="5">
                <template v-if="field.key === 'ps'">
                  Agente necessita de determinação de atividade
                </template>
                <template v-else>
                  {{ model.getValue(field.key, item) }}
                </template>
              </td>
            </template>
          </tr>
        </template>
        <tr :key="`blank_${item.legalSearchId}`">
          <td colspan="6"></td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
import Ltcat from '@/models/Ltcat'
export default {
  data() {
    return {
      model: Ltcat,
      selectedAgent: {},
    }
  },
  computed: {
    summary() {
      return this.$store.getters['legalSearch/summary']
    },
    user() {
      return this.$store.getters['user/current']
    },
  },
  async mounted() {
    await this.$store.dispatch('userData/updatePrintedReports', this.user.id)
    window.print()
  },
}
</script>

<style scoped>
@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
}

.emph {
  background: var(--sapphire-blue);
  color: white;
  font-weight: bold;
  text-align: center;
}

td.data-center {
  text-align: center;
}

th,
td {
  padding: 10px;
}

th:first-child {
  padding: 0;
}

th:first-child img {
  width: 100%;
}

.col-first {
  width: 180px;
}
</style>
